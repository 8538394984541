import { graphql } from "gatsby";

import WithSidebarPage from "../../../layouts/WithSideBarPage";
import TimeLine from "../../../components/TimeLine";
import Heading from "../../../components/Heading";
import Title from "../../../components/Title";
import Mark from "../../../components/Marker";

import { createClassName, setLocaleContent } from "../../../helpers";
import "./style.scss";

import {
  ul,
  li,
  insideLink,
  downloadLink,
  paragraph,
  outsideLink,
  onResourcewithArrowLink,
  note,
  arrowIcon,
  heading,
  renderCom,
} from "../../../components/ForMarkDownComponents";

const cn = createClassName("release");

const releaseLi = ({ children, bottomReduce = true }) => li({ children, bottomReduce });

const releaseParagraph = ({ children, bottom = true }) => paragraph({ children, bottom });

const title = ({
  children, id, sidebar, fontLevel = 3,
}) => (
  <div id={sidebar || id} className={cn("heading")}>
    {heading({ children, id, fontLevel })}
  </div>
  );

const subTitle = ({ children }) => (
  <Heading level={3} fontLevel={4} weight="medium" className={cn("title")}>
    {children}
  </Heading>
);

const setCurrentMarkForTittle = ({
  children, text, link, href = "/download-free-trial/",
}) => (
  <div className={cn("current", { wrap: true })}>
    {subTitle({ children })}
    <Mark text={text} className={cn("current", { text: true })} />
  </div>
  );

const renderComToolkit = {
  ul,
  li: releaseLi,
  title,
  h3: subTitle,
  p: releaseParagraph,
  arrow: arrowIcon,
  note,
  "current-version": setCurrentMarkForTittle,
  "inside-link": insideLink,
  "download-link": downloadLink,
  "outside-link": outsideLink,
  "arrow-link": onResourcewithArrowLink,
};

const realiseRenderCom = renderCom(renderComToolkit);

const Release = (data) => {
  const { htmlAst, frontmatter } = data.data.markdownRemark;

  const {
    sidebarChildrenTitle,
    seo,
    versionList,
    contentBeforeMainSection,
  } = frontmatter;

  const contentBeforeMainSectionBlock = (
    <>
      <Heading level={1} fontLevel={2} align="center" weight="normal">
        {contentBeforeMainSection[0].title}
      </Heading>
      <Title level={5} align="center">
        {contentBeforeMainSection[0].subTitle}
      </Title>
      <div className={cn({ timeline: true })}>
        <TimeLine versionList={versionList} />
      </div>
    </>
  );

  return (
    <WithSidebarPage
      seo={seo[0]}
      sidebarChildrenTitle={sidebarChildrenTitle}
      headingsClassName=".release__heading"
      className={cn({ wrap: true })}
      contentBeforeMainSection={contentBeforeMainSectionBlock}
    >
      {realiseRenderCom(htmlAst)}
    </WithSidebarPage>
  );
};

export default Release;

export const query = graphql`
  query getRelease($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        bannerTitle
        bannerSubTitle
        sidebarChildrenTitle
        seo {
          description
          link
          title
        }
        versionList {
          current
          version
        }
        contentBeforeMainSection {
          title
          subTitle
        }
      }
    }
  }
`;
